import React from "react"

import background from '../../../../../assets/img/stays/thailand/phuket/city.jpg'
/* places */
import temple from '../../../../../assets/img/stays/thailand/phuket/diamond/temple.jpg'
import patong from '../../../../../assets/img/stays/thailand/phuket/diamond/patong.jpg'
import boat from '../../../../../assets/img/stays/thailand/phuket/diamond/boat.jpg'
import crique from '../../../../../assets/img/stays/thailand/phuket/diamond/crique.jpg'
import buda from '../../../../../assets/img/stays/thailand/phuket/diamond/buda.jpg'
import junglebeach from '../../../../../assets/img/stays/thailand/phuket/diamond/junglebeach.jpg'
/* activities includes */
import boxthai from '../../../../../assets/img/stays/thailand/phuket/diamond/boxthai.jpg'
import elephant from '../../../../../assets/img/stays/thailand/phuket/diamond/elephant.jpg'
import jamesbond from '../../../../../assets/img/stays/thailand/phuket/diamond/jamesbond.jpg'
/* activities not includes */
import boxthai2 from '../../../../../assets/img/stays/thailand/phuket/diamond/boxthai2.jpg'
import jetski from '../../../../../assets/img/stays/thailand/phuket/diamond/jetski.jpg'
import kayak from '../../../../../assets/img/stays/thailand/phuket/diamond/kayak.jpg'
import fish from '../../../../../assets/img/stays/thailand/phuket/diamond/fish.jpg'
import monkey from '../../../../../assets/img/stays/thailand/phuket/diamond/monkey.jpg'
import scuba from '../../../../../assets/img/stays/thailand/phuket/diamond/scuba.jpg'
/* hotel */
import room from '../../../../../assets/img/stays/thailand/phuket/diamond/room.jpg'
import hotel from '../../../../../assets/img/stays/thailand/phuket/diamond/hotel.jpg'
import hotelview from '../../../../../assets/img/stays/thailand/phuket/diamond/hotelview.jpg'
import pool from '../../../../../assets/img/stays/thailand/phuket/diamond/pool.jpg'
import pool2 from '../../../../../assets/img/stays/thailand/phuket/diamond/pool2.jpg'
import sport from '../../../../../assets/img/stays/thailand/phuket/diamond/sport.jpg'
/* room */
import double from '../../../../../assets/img/stays/thailand/phuket/diamond/double.jpg'
import twin from '../../../../../assets/img/stays/thailand/phuket/diamond/twin.jpg'
/* days */
import thailand from '../../../../../assets/img/icons/countries/svg/thailand.svg'
import france from '../../../../../assets/img/icons/countries/svg/france.svg'
/* airport */
import cdg from '../../../../../assets/img/stays/thailand/phuket/diamond/cdg.webp'
import hkt from '../../../../../assets/img/stays/thailand/phuket/diamond/hkt.jpg'

const stay = {
  id: 'thailand-phuket-diamond',
  slug: 'thailand-phuket-diamond',
  name: 'Phuket Diamond',
  description: 'Un séjour inoubliable à Phuket accompagné de 10 autres indépendants dans hôtel 5 étoiles avec une plage privée',
  descriptionHead: <span>Un séjour inoubliable dans un hôtel 5 étoiles avec une plage privée</span>,
  descriptionLong: 'Un séjour inoubliable à Phuket accompagné de 10 autres indépendants dans un hôtel 5 étoiles avec une plage privée. Pendant ce séjour tu vas pouvoir faire pleins d\'activités extras ! Mais tu pourras également travailler et échanger avec d\'autres indépendants. Tout cela où ? Simplement au bord des plus belles plages du monde ! N\'attend plus et participe à un séjour de 10 jours tout simplement inoubliable.',
  url: '/stay/thailand/phuket/diamond',
  images: {
    background: background,
    activities: [
      boxthai,
      elephant,
      jamesbond
    ],
    hotel: [
      pool,
      room,
      hotelview,
      pool2,
      hotel,
      sport
    ],
    room: {
      double: double,
      twin: twin
    },
    resume: [//common
      pool,
      buda,
      crique,
      room,
      boxthai,
      junglebeach,
      patong,
      elephant,
      temple,
      jetski
    ]
  },
  checkLists: [
    {
      name: 'Inclus ? Pas inclus ?',
      infos: '* Départ au choix depuis Paris, Lyon, Lille, Marseille, Toulouse, Bordeaux, Nantes ou Montpellier',
      lists: [
        {
          status: 'true',
          items: [
            "L'aller-retour depuis l'un des principaux aéroports de France*",
            "Les transferts entre l'hôtel et l'aéroport de Phuket",
            "Une chambre dans un hôtel 5 étoiles avec plage privée (9 nuits)",
            "9 petits-déjeuners (1 chaque jour)",
            "Une salle de travail du lundi au vendredi",
            "7 repas",
            "Une matinée en compagnie des éléphants",
            "Ue journée entre bateau et plages entre des falaises",
            "Une soirée à un match de boxe thaï",
            "Le transport pour chaque activité"
          ]
        },
        {
          status: 'false',
          items: [
            "Le transport entre chez toi et l'aéroport en France",
            "Toutes les activités que tu souhaites faire en plus",
            "Tous les déplacements non prévues pour le groupe"
          ]
        }
      ]
    }
  ],
  program: [
    {
      name: 'Jeudi - Vendredi',
      image: thailand,
      title: 'Phuket nous voilà !',
      content: {
        text: 'C\'est le grand jour ! Rendez-vous à l\'aéroport pour rejoindre les autres participants au séjour. Décollage de l\'aéroport en France et atterrissage à celui de Phuket avec 16h de vol. Ensuite, on vient te chercher avec ton groupe et direction l\'hôtel pour que tu puisses t\'installer dans ta chambre 🛫',
        images: [cdg, hkt, room]
      } 
    },
    {
      name: 'Samedi',
      image: elephant,
      title: 'Nos amis les animaux ! (Et nos amis les boxeurs)',
      content: {
        text: 'On commence bien ce séjour avec une journée auprès des éléphants. Découvre ces animaux majestueux ! 🐘 Et ensuite direction l\'un des nombreux stades de boxe thaï pour assister à une série de match du sport national 🥊',
        images: [elephant, boxthai]
      } 
    },
    {
      name: 'Dimanche',
      image: crique,
      title: 'Journée rien que pour toi',
      content: {
        text: 'Cette journée elle est pour toi. Une activité que tu veux faire en particulier ? Un lieu à visiter ? Comme tu voudras, tu es libre 🙃',
        images: [jetski, kayak, boxthai2]
      }
    },
    {
      name: 'Lundi - Vendredi',
      image: hotelview,
      title: 'Le travail c\'est bien, au soleil c\'est mieux',
      content: {
        text: 'Rien de spécifique, simplement une salle de travail à ta disposition avec ton groupe pour travailler dans un cadre idyllique 💻',
        images: [pool2, hotelview, pool]
      }
    },
    {
      name: 'Samedi',
      image: boat,
      title: 'Journée rien que pour toi',
      content: {
        text: 'Cette journée elle est pour toi. Une activité que tu veux faire en particulier ? Un lieu à visiter ? Comme tu voudras, tu es libre 🙃',
        images: [jetski, kayak, boxthai2]
      }
    },
    {
      name: 'Dimanche',
      image: jamesbond,
      title: 'Les Robinson Crusoé, le retour !',
      content: {
        text: 'Aujourd\'hui direction une des plus célèbres île entourant Phuket. Au programme tour en bateau et repas sur l\'eau. Baignade et découverte tous ensemble !',
        images: [jamesbond, crique]
      }
    },
    {
      name: 'Lundi',
      image: france,
      title: 'Retour à Paris',
      content: {
        text: 'On revient du safari en se rendant directement à l\'aéroport, décollage de l\'aéroport de Phuket et atterrissage à celui de Paris avec 16h de vol 🛬',
        images: [hkt, cdg]
      }
    }
  ],
  infos: [
    {
      name: 'Hôtel',
      description: 'Un hôtel 5* à côté de la plus grande plage de Phuket !',
      images: [
        room,
        pool,
        hotelview,
        pool2,
        hotel,
        sport
      ]
    },
    {
      name: 'Activités',
      description: 'Des activités variées pour te faire découvrir Phuket',
      images: [
        boxthai,
        elephant,
        jamesbond
      ]
    },
    {
      name: 'Activités Optionnelles',
      description: 'Mais tu peux aussi faire d\'autres activitiés',
      images: [
        scuba,
        jetski,
        boxthai2,
        kayak,
        monkey,
        fish
      ]
    },
    {
      name: 'Destinations',
      description: 'Ici c\'est une petite liste des endroits que tu peux trouver à Phuket',
      images: [
        temple,
        crique,
        boat,
        buda,
        junglebeach,
        patong
      ]
    }
  ],
  faq: [
    {
      question: 'Depuis où puis-je prendre l\'avion ? Quelle est la durée du trajet ?',
      answer: 'Il est possible de partir depuis Paris, Lyon, Lille, Marseille, Toulouse, Bordeaux, Nantes ou Montpellier. Si tu pars depuis Paris il n\'y a aura qu\'un seul vol qui durera environ 16h.'
    },
    {
      question: 'Combien de valise je peux prendre ?',
      answer: 'Le billet d\'avion est prévu pour un bagage cabine et un bagage à main.'
    },
    {
      question: 'Peux-t-on réserver pour plusieurs personnes ?',
      answer: 'Il faut faire une réservation distincte pour chaque participant.'
    },
    {
      question: 'Combien de personne par séjour ?',
      answer: 'Vous serez entre 8 et 12 indépendants par séjour.'
    },
    {
      question: 'Quand ferme les réservations ?',
      answer: 'Les réservations ferment 20 jours avant la date de départ prévue.'
    },
    {
      question: 'Que se passe-t-il si il y a moins de 10 personnes qui on réservé avant la cloture ?',
      answer: 'Si lors de la cloture des réservations le groupe est composé de moins de 10 personnes alors nous te contacterons afin de te proposer un séjour similaire à la même date. Si aucun séjour de te correspond nous te fournirons un avoir afin que tu puisses participer à ce séjour sur une autre date.'
    },
    {
      question: 'Que se passe-t-il si la covid-19 empêche le séjour ?',
      answer: 'Si la covid-19 empêche le départ pour Phuket alors nous te contacterons afin de te proposer un séjour similaire à la même date. Si aucun séjour de te correspond nous te fournirons un avoir afin que tu puisses participer à ce séjour sur une autre date.',
      covid: true
    },
    {
      question: 'Quelles sont les conditions pour participer à un séjour ?',
      answer: 'Il te suffit d\'avoir plus de 18 ans à la date de départ et d\'avoir un passeport valide jusque 6 mois après la date de retour prévue.'
    },
    {
      question: 'Quelles sont les conditions liés à la covid-19 pour participer à un séjour ?',
      answer: 'Il faudra simplement que tu puisses justifier, à l\'arriver, d\'un test PCR négatif effectué moins de 72h avant.',
      covid: true
    },
    {
      question: 'Que se passe-t-il si je suis testé positif à la covid-19 avant mon départ ?',
      answer: 'Dans le cas où votre test PCR est positif alors nous annulerons ton séjour et te fournirons un avoir équivalent à 75% du prix de ton séjour annulé.',
      covid: true
    }
  ],
  place: {
    name: 'Kamala',
    map: <iframe title="thailand-kamala" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Kamala+-+Thaïlande&amp;ie=UTF8&amp;t=&amp;z=12&amp;iwloc=B&amp;output=embed"></iframe>
  },
  datas: {
    stars: 5,
    category: 'diamond',
    starsName: 'Hôtel 5 étoiles avec plage',
    price: 2170,
    days: 11,
    nights: 10,
    recommand: true
  }
}

export default stay